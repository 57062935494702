<template>
  <div 
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--dynamic-white">
      <div class="ph-inpage-widget__body">
        <div class="ph-inpage-widget__content">
          <div class="ph-inpage-widget__row ph-inpage-widget__row_center">
            <div class="ph-inpage-widget__thumb ph-inpage-widget__thumb-rounded-100">
              <img v-show="!imgIcon" :src="require('@/assets/panel/images/inpage/empty.svg')" alt="">
              <img :src="imgIcon" v-show="imgIcon" alt="">
            </div>
            <div class="ph-inpage-widget__txt">
              <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-1">{{ adTitle }}</div>
            </div>
          </div>
          <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">{{ adText }}</div>
          <div class="ph-inpage-widget__close ph-inpage-widget__hide-message">&#10005;</div>
          <div class="ph-inpage-widget__triangle2"></div>
        </div>
        <div class="ph-inpage-widget__circle ph-inpage-widget__open-content">
          <div class="ph-inpage-widget__circle-thumb">
            <img v-show="!imgIcon" :src="require('@/assets/panel/images/inpage/empty.svg')" alt="">
            <img :src="imgIcon" v-show="imgIcon" alt="">
          </div>
          <div class="ph-inpage-widget__circle-notification">1</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>